import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';
import './index.css';
import castSubsonic from 'subfirebase/lib/castSubsonic';
import App from './App';
import * as serviceWorker from './serviceWorker';

window.openSmall = function() {
  window.open(window.location.href, '_new_', 'width=360, height=600, location=no, toolbar=no, resizable=yes, status=no, menubar=no');
};

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.register();

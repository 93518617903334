import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
});

class TimerType extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Grid container spacing={1}>
        <Grid item xs={6} sm={3}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="continuous">Continuous</InputLabel>
            <Select
              value={this.props.continuous}
              onChange={this.props.updateTimerContinuous}
              inputProps={{
                name: "continuous",
                id: "continuous"
              }}
            >
              <MenuItem value="false">One Song</MenuItem>
              <MenuItem value="true">Continuous</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="toothbrush-length">Teeth</InputLabel>
            <Select
              value={this.props.toothbrushLength}
              onChange={this.props.updateTimerToothbrushLength}
              inputProps={{
                name: "toothbrush-length",
                id: "toothbrush-length"
              }}
            >
              <MenuItem value={0}>
                <em>No First Timer</em>
              </MenuItem>
              <MenuItem value={120}>2 Minutes</MenuItem>
              <MenuItem value={180}>3 Minutes</MenuItem>
              <MenuItem value={240}>4 Minutes</MenuItem>
              <MenuItem value={300}>5 Minutes</MenuItem>
              <MenuItem value={600}>10 Minutes</MenuItem>
              <MenuItem value={1200}>20 Minutes</MenuItem>
              <MenuItem value={20}>20 Second Handwashing</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel htmlFor="finish-length">Finish</InputLabel>
            <Select
              value={this.props.finishLength}
              onChange={this.props.updateTimerFinishLength}
              inputProps={{
                name: "finish-length",
                id: "finish-length"
              }}
            >
              <MenuItem value="0-s">Stop on first timer</MenuItem>
              <MenuItem value="0-f">Finish song</MenuItem>
              <MenuItem value="300-s">5 minutes</MenuItem>
              <MenuItem value="300-f">5 minutes and finish song</MenuItem>
              <MenuItem value="600-s">10 minutes</MenuItem>
              <MenuItem value="600-f">10 minutes and finish song</MenuItem>
              <MenuItem value="1800-s">30 minutes</MenuItem>
              <MenuItem value="1800-f">30 minutes and finish song</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(TimerType);

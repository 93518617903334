import React, { Component, Fragment } from "react";

import { withStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import ShuffleIcon from "@material-ui/icons/Shuffle";

import PlaylistsBrowser from "subfirebase/lib/subsonic/components/browsers/PlaylistsBrowser";
import SubsonicActions from "subfirebase/lib/subsonic/actions/SubsonicActions";
import TimerType from "./TimerType";

import { toHHMMSS, arrayShuffle } from "subfirebase/lib/utils/utils";

export const key1 = "subfire.timer.currentPlaylist";
export const key2 = "subfire.timer.renderAll";
const styles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  root: {
    color: "#ffffff",
    "&$checked": {
      color: "#ffffff"
    }
  },
  checked: {
    color: "#ffffff"
  }
});

class TimerMain extends Component {
  constructor(props) {
    super(props);

    // init current folder from local storage
    const cp = localStorage.getItem(key1);
    let renderAll = localStorage.getItem(key2);
    renderAll = renderAll === "false" ? false : Boolean(renderAll).valueOf();
    this.state = {
      currentPlaylist: cp || null,
      playlistEntries: [],
      renderAll: renderAll
    };
  }

  loadPlaylist = () => {
    const id = this.state.currentPlaylist;
    SubsonicActions.selectPlaylist.defer([id]);
  };

  selectPlaylist = id => {
    console.log("id", id);
    localStorage.setItem(key1, "" + id);
    this.setState({
      currentPlaylist: "" + id,
      playlistEntries: []
    });
    SubsonicActions.selectPlaylist([id]);
  };

  static getDerivedStateFromProps(props, state) {
    if (props.subsonic.playlistEntries === state.playlistEntries) return {};
    return { playlistEntries: props.subsonic.playlistEntries };
  }

  shufflePlaylist = () => {
    this.setState({
      playlistEntries: arrayShuffle(this.state.playlistEntries)
    });
  };

  componentDidMount() {
    var that = this;
    setTimeout(() => {
      if (
        that.state.currentPlaylist &&
        (!that.props.subsonic.playlistEntries ||
          that.props.subsonic.playlistEntries.length === 0)
      ) {
        SubsonicActions.selectPlaylist.defer([that.state.currentPlaylist]);
      }
    }, 1500);
  }

  componentWillUnmount() {
    const background = "rgb(0,0,0)";
    document.getElementsByTagName("body")[0].style.background = background;
  }

  playSong = song => {
    this.props.playSong(song, this.state.playlistEntries, this.props.history);
  };

  handleCheckChange = name => event => {
    this.setState({ [name]: event.target.checked });
    localStorage.setItem(key2, "" + event.target.checked);
  };

  render() {
    const { subsonic, SubsonicSource, classes } = this.props;
    const { renderAll } = this.state;
    // console.log("render", subsonic.playlist, subsonic.playlistEntries.length);
    const body = document.getElementsByTagName("body")[0];
    if (!body.getAttribute("style")) {
      const background =
        "linear-gradient(0deg, rgba(0,0,0,1) 54%, rgba(211,47,47,1) 65%, rgba(166,35,35,1) 80%)";
      body.style.background = background;
    }

    const SelectPlaylistsBrowser = props => (
      <PlaylistsBrowser
        {...props}
        selectedId={this.state.currentPlaylist}
        onSelect={this.selectPlaylist}
        renderAllPlaylists={renderAll}
        renderMode={PlaylistsBrowser.renderTypes.SELECT}
      />
    );

    const songs = (this.state.playlistEntries || []).slice(0, 700);

    const songSection = songs.map(item => (
      <ListItem
        key={`item-${item.id}`}
        button
        onClick={this.playSong.bind(this, item, this.props.history)}
      >
        <ListItemIcon>
          <img
            alt=""
            style={{
              width: 24,
              height: 24
            }}
            src={SubsonicSource.getCoverArtURL(item.coverArt, 24)}
          />
        </ListItemIcon>
        <ListItemText primary={`${item.title} - ${toHHMMSS(item.duration)}`} />
      </ListItem>
    ));

    const loadingSongSection = subsonic.isBusy ? (
      "Loading Playlist..."
    ) : songs.length === 0 ? (
      <Button onClick={this.loadPlaylist}>No songs</Button>
    ) : (
      songSection
    );

    const timerProps = Object.assign({}, this.props);
    delete timerProps.classes;

    return (
      <Fragment>
        <TimerType {...timerProps} />
        <Grid container={true} spacing={1}>
          <Hidden xsDown={true}>
            <Grid item={true} xs={false} sm={3} />
          </Hidden>
          <Grid item={true} xs={8} sm={5}>
            <FormControl className={classes.formControl} fullWidth>
              <SelectPlaylistsBrowser {...this.props} />
            </FormControl>
          </Grid>
          <Grid item={true} xs={2} sm={1}>
            <Checkbox
              classes={{
                root: classes.root,
                checked: classes.checked
              }}
              color="secondary"
              checked={renderAll}
              onChange={this.handleCheckChange("renderAll")}
              value="renderAll"
            />
          </Grid>
          <Grid item={true} xs={2} sm={1}>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Shuffle"
              onClick={this.shufflePlaylist}
            >
              <ShuffleIcon />
            </IconButton>
          </Grid>
        </Grid>
        {loadingSongSection}
      </Fragment>
    );
  }
}
export default withStyles(styles)(TimerMain);

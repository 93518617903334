import React, { Component, Fragment } from "react";
import { HashRouter as Router, Route, Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import CssBaseline from "@material-ui/core/CssBaseline";
// import { deepPurple, green } from "@material-ui/core/colors";

import SubsonicActions from "subfirebase/lib/subsonic/actions/SubsonicActions";
import CredentialsActions, {
  allocateDigits
} from "subfirebase/lib/subsonic/actions/CredentialsActions";
import CredentialsContainer from "subfirebase/lib/subsonic/components/containers/CredentialsContainer";
import LoginCredentials from "subfirebase/lib/subsonic/components/creds/LoginCredentials";
import AuthCodeClient from "subfirebase/lib/subfire/components/creds/AuthCodeClient";
import AuthCodeSource from "subfirebase/lib/subfire/components/creds/AuthCodeSource";
import SubsonicContainer from "subfirebase/lib/subsonic/components/containers/SubsonicContainer";
import ServerSelect from "subfirebase/lib/subsonic/components/browsers/ServerSelect";
import { emptytrack } from "subfirebase/lib/subsonic/stores/SubsonicStore";
import { toHHMMSS } from "subfirebase/lib/utils/utils";

import ColorContainer from "subfirebase/lib/subfire/components/containers/ColorContainer";
import ColorTheme from "subfirebase/lib/subfire/components/containers/ColorTheme";

import TimerMain from "./components/TimerMain.js";
import TimerPlayer from "./components/TimerPlayer";
import Snacker from "subfirebase/lib/subsonic/components/controls/Snacker";

import CastSubsonicContext, {
  CastSubsonicProvider
} from "subfirebase/lib/subsonic/context/CastSubsonic";

window.subsonicClientName = "SubFireTimer";

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  main: {
    position: "absolute",
    top: "64px",
    bottom: 10,
    left: 10,
    right: 10,
    overflow: "scroll"
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  select: {
    color: "black"
  }
});

// const theme = createMuiTheme({
//   palette: {
//     primary: purple,
//     secondary: grey,
//     type: "dark"
//   }
// });

const continuousKey = "subfire.timer.continuousState";
const initContinuousState = localStorage.getItem(continuousKey) === "true";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      continuous: initContinuousState ? "true" : "false",
      toothbrushLength: 120,
      finishLength: "300-f",
      updateTimerContinuous: this.updateTimerContinuous,
      updateTimerToothbrushLength: this.updateTimerToothbrushLength,
      updateTimerFinishLength: this.updateTimerFinishLength
    };
    CredentialsActions.doLogin.defer();
  }

  playSong = (song, playlistEntries, history) => {
    console.log("playSong", song, playlistEntries, history);
    const c = this.state.continuous === "true";
    const split = this.state.finishLength.split("-");
    const length = (this.state.toothbrushLength + split[0] * 1);
    const finish = split[1] === "f";
    // assemble the queue
    const queue = [];
    let total = 0;
    let j = playlistEntries.indexOf(song);
    if (j === -1) {
      console.warn("could not find", song);
      return;
    }
    for (let i = 0; i < 20; ++i) {
      queue.push(playlistEntries[j]);
      total += playlistEntries[j].duration * 1;
      if (c) j++;
      if (j >= playlistEntries.length) j = 0;
      queue.push(emptytrack);
      total += emptytrack.duration * 1;
      if (total > length) break;
    }
    if (finish) total += 2;
    // a little padding
    else total = length;

    console.log("playing", queue, toHHMMSS(total));
    SubsonicActions.startPlaying(queue, 0);
    history.push("/player/" + total);
  };

  updateTimerContinuous = evt => {
    this.setState({ continuous: evt.target.value });
    localStorage.setItem(continuousKey, evt.target.value);
  };

  updateTimerToothbrushLength = evt => {
    if (evt.target.value + "" === '20') {
      this.setState({ finishLength: '0-s' });
    }
    this.setState({ toothbrushLength: evt.target.value });
  };

  updateTimerFinishLength = evt => {
    this.setState({ finishLength: evt.target.value });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCredentialsSubmit = (id, creds) => {
    console.log("logging in or updating", creds);
    CredentialsActions.saveCredentials(id, creds, true);
    window.localStorage.removeItem(TimerMain.key);
  };

  handleServerChange = evt => {
    console.log(evt.target.value);
    CredentialsActions.changeCurrent(evt.target.value, true);
    window.localStorage.removeItem(TimerMain.key);
  };

  handleCredentialsDelete = id => {
    console.log("delete", id);
    CredentialsActions.deleteCredentials(id);
  };

  render() {
    const { classes } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    // dialogs
    const loginDialog = route => (
      <CredentialsContainer route={route}>
        <LoginCredentials
          handleSubmit={this.handleCredentialsSubmit}
          handleDelete={this.handleCredentialsDelete}
        />
      </CredentialsContainer>
    );

    const digits = allocateDigits();

    const linkClient = route => (
      <AuthCodeClient
        route={route}
        handleClose={route.history.goBack}
        digits={digits}
      />
    );

    const linkSource = route => (
      <AuthCodeSource
        route={route}
        handleClose={route.history.goBack}
        handleSubmit={this.handleACSubmit}
      />
    );

    const menu = (
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        open={open}
        onClose={this.handleClose}
      >
        <MenuItem component={Link} to="/login" onClick={this.handleClose}>
          Credentials
        </MenuItem>
        <MenuItem
          xs={6}
          component={Link}
          to="/linkClient"
          onClick={this.handleClose}
        >
          Link Client
        </MenuItem>
        <MenuItem
          xs={6}
          component={Link}
          to="/linkSource"
          onClick={this.handleClose}
        >
          Link Source
        </MenuItem>
        <MenuItem
          xs={6}
          onClick={() => {
            window.openSmall();
            this.handleClose();
          }}
        >
          Go Small
        </MenuItem>
        <Divider />
        <MenuItem>{"Version 2 0.34.0 - Copyright"}</MenuItem>
      </Menu>
    );

    return (
      <CastSubsonicProvider>
        <Snacker />
        <div className="App">
          <CssBaseline />
          <ColorContainer>
            <ColorTheme
              defaultPrimary={{
                main: "#d32f2f"
              }}
              defaultSecondary={{
                main: "#2fd32f"
              }}
            >
              <Router basename="/">
                <Fragment>
                  <AppBar position="absolute" color="secondary">
                    <Toolbar variant="dense">
                      <IconButton
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="Menu"
                        aria-owns={open ? "menu-appbar" : null}
                        aria-haspopup="true"
                        onClick={this.handleMenu}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.flex}
                        flex={1}
                      >
                        SubFire Timer
                      </Typography>
                      {menu}
                      <div flex={1}>
                        <CredentialsContainer>
                          <ServerSelect
                            xs={6}
                            handleServerChange={this.handleServerChange}
                          />
                        </CredentialsContainer>
                      </div>
                    </Toolbar>
                  </AppBar>

                  <div className={classes.main}>
                    <Route
                      exact
                      path="/"
                      render={props => (
                        <SubsonicContainer>
                          <TimerMain
                            {...props}
                            {...this.state}
                            playSong={this.playSong}
                          />
                        </SubsonicContainer>
                      )}
                    />

                    <Route
                      path="/player/:l"
                      render={props => (
                        <CastSubsonicContext.Consumer>
                          {csProps => (
                            <SubsonicContainer {...props}>
                              <TimerPlayer
                                useFuzzyImage
                                csProps={csProps}
                                {...props}
                                {...this.state}
                              />
                            </SubsonicContainer>
                          )}
                        </CastSubsonicContext.Consumer>
                      )}
                    />

                    <Route path="/login" component={loginDialog} />
                    <Route path="/linkClient" component={linkClient} />
                    <Route path="/linkSource" component={linkSource} />
                  </div>
                </Fragment>
              </Router>
            </ColorTheme>
          </ColorContainer>
        </div>
      </CastSubsonicProvider>
    );
  }
}

export default withStyles(styles)(App);

import React, { Component } from "react";
// import utils from 'subfirebase/lib/utils/utils.js';
// const toHHMMSS = utils.toHHMMSS;

class TimerDial extends Component {
  setSize = () => {
    if (!this.canvas) return;
    var div = this.canvas.parentNode;
    this.canvas.height = Math.min(div.clientHeight, div.clientWidth);
    this.canvas.width = this.canvas.height;
  };

  componentDidMount() {
    this.setSize();
    this.updateCanvas();
    window.addEventListener("resize", this.setSize);
  }

  componentDidUpdate() {
    this.updateCanvas();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setSize);
  }

  updateCanvas() {
    // console.log(this.canvas);
    // console.log(this.canvas.height);

    const canvas = this.canvas;
    const s = this.props;
    const timerMax = s.original > 600 ? 1800 : s.original === 20 ? 20 : 600;
    const center = canvas.height / 2;
    const ctx = this.canvas.getContext("2d");

    const plentyRemainingTimeColor = s.plentyRemainingTimeColor || "#4caf50";
    const normalRemainingTimeColor = s.normalRemainingTimeColor || "#aed581";
    const notMuchRemainingTimeColor = s.notMuchRemainingTimeColor || "#f44336";
    const teethColor = s.teethColor || "blue";
    const loopColor = s.loopColor || "white";

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // first draw full circle in white
    ctx.save();
    ctx.beginPath();
    ctx.fillStyle = loopColor;
    ctx.arc(center, center, center, 0, Math.PI * 2);
    ctx.lineTo(center, center);
    ctx.closePath();
    ctx.fill();
    ctx.restore();

    // second draw the remaining time in green, until less than 1 minute then in red
    let percentAround = s.remaining / timerMax;

    let start = -Math.PI / 2;
    let end = start + Math.PI * 2 * percentAround;

    ctx.save();
    ctx.beginPath();
    ctx.fillStyle =
      s.remaining > 60
        ? s.remaining > 300
          ? plentyRemainingTimeColor
          : normalRemainingTimeColor
        : notMuchRemainingTimeColor;
    ctx.arc(center, center, center * 0.75, start, end);
    ctx.lineTo(center, center);
    ctx.closePath();
    ctx.fill();
    ctx.restore();

    // third draw the teeth time inversed blue
    if (s.teeth >= 0 || (s.teeth > 0 && s.remaining > 60)) {
      percentAround = (s.teethTotal - s.teeth) / (s.teethTotal || 120);
      start = Math.PI;
      end = percentAround < 0 ? start : start + Math.PI * 2 * percentAround;

      // console.log(percentAround, start, end);

      ctx.save();
      ctx.beginPath();
      ctx.fillStyle = s.teeth > 0 ? teethColor : loopColor;
      ctx.arc(center, center, center * 0.5, start, end);
      ctx.lineTo(center, center);
      ctx.closePath();
      ctx.fill();
      ctx.restore();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.size !== this.props.size ||
      nextProps.teeth !== this.props.teeth ||
      nextProps.remaining !== this.props.remaining
    )
      return true;
    return false;
  }

  render() {
    const p = this.props;

    // var remaining = p.teeth
    //   ? p.teeth
    //   : p.remaining;

    var style = {
      width: p.size || "100%",
      height: p.size || "100%"
    };

    var canvasStyle = {};

    // var content = (
    //   <div>
    //     {p.teeth
    //       ? "Teeth: "
    //       : "Dress: "}
    //     {toHHMMSS(remaining)}
    //     <hr/> {p.remaining}
    //   </div>
    // );

    return (
      <div style={style}>
        <canvas
          style={canvasStyle}
          ref={canvas => {
            this.canvas = canvas;
          }}
        />
      </div>
    );
  }
}

export default TimerDial;

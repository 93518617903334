import React, { Component } from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import { emptytrack } from "subfirebase/lib/subsonic/stores/SubsonicStore";

import { PlayPauseButton } from "subfirebase/lib/subsonic/components/controls/MediaButtons";
import SubsonicActions from "subfirebase/lib/subsonic/actions/SubsonicActions";
import Notification from "subfirebase/lib/subsonic/components/controls/Notification";

import ColorActions from "subfirebase/lib/subfire/actions/ColorActions";
import ColorContainer from "subfirebase/lib/subfire/components/containers/ColorContainer";
import GradientGeneratingImage from "subfirebase/lib/subfire/components/GradientGeneratingImage";
import GradientBackground from "subfirebase/lib/subfire/components/GradientBackground";
import FuzzyImageBackground from "subfirebase/lib/subfire/components/FuzzyImageBackground";

import HollowCard from "subfirebase/lib/subsonic/components/styled/HollowCard";

import PaletteDial from "./PaletteDial";

class TimerPlayer extends Component {
  static XXgetDerivedStateFromProps(props, prevState) {
    const { subsonic, SubsonicSource } = props;
    const { currentPlaying } = subsonic;

    let newState;

    if (!currentPlaying.queue.length) {
      newState = {
        showBackground: false,
        currentFile: emptytrack
      };
    } else {
      const currentFile = currentPlaying.queue[currentPlaying.idx];
      if (!prevState || currentFile !== prevState.currentFile) {
        const isWaitingForNext = false;
        const coverArt = SubsonicSource.getCoverArtURL(currentFile.id, 300);
        const mediaSrc =
          currentFile.localPath ||
          (currentFile.src && currentFile.src.startsWith("http")
            ? currentFile.src
            : SubsonicSource.getStreamingURL(currentFile.id));
        const mediaIcon =
          SubsonicSource.getCoverArtURL(currentFile.id, 192) + "&co=false";

        // now load castplayer
        currentFile.coverArtURL = coverArt;
        currentFile.src = mediaSrc;
        window.cp.playerHandler.load(currentFile, 0);
        // console.warn(currentFile);
        newState = {
          currentFile,
          coverArt,
          mediaSrc,
          mediaIcon,
          isWaitingForNext
        };
      }
    }
    return Object.assign({}, prevState, newState);
  }

  static getDerivedStateFromProps(props, prevState) {
    const { subsonic = {}, SubsonicSource, csProps } = props;
    const { isConnected, currentPlaying } = subsonic;
    if (!isConnected) {
      return prevState;
    }
    let newState;

    if (!currentPlaying.queue.length) {
      newState = {
        showBackground: false,
        currentFile: emptytrack,
        currentPlaying: currentPlaying
      };
    } else {
      const currentFile = currentPlaying.queue[currentPlaying.idx];
      const p = csProps;
      // console.log(p.reason);
      let {
        showBackground,
        isWaitingForNext,
        coverSize,
        coverArt,
        mediaSrc,
        mediaIcon,
        reason
      } = prevState;
      if (currentFile !== prevState.currentFile) {
        console.warn("next track...");
        isWaitingForNext = false;
        coverSize = Math.min(
          Math.trunc(window.innerWidth / 2),
          window.innerHeight - 200
        );
        coverArt = SubsonicSource.getCoverArtURL(currentFile.id, coverSize);
        mediaSrc =
          currentFile.localPath ||
          (currentFile.src && currentFile.src.startsWith("http")
            ? currentFile.src
            : SubsonicSource.getStreamingURL(currentFile.id));
        mediaIcon =
          SubsonicSource.getCoverArtURL(currentFile.id, 192) + "&co=false";

        // now load castplayer
        currentFile.coverArtURL = coverArt;
        currentFile.src = mediaSrc;
        if (
          !window.cp.currentMedia ||
          window.cp.currentMedia.src !== mediaSrc
        ) {
          window.cp.playerHandler.load(currentFile, 0);
        }
      } else {
        // console.warn('checking', prevState.isWaitingForNext, reason, p.reason);
        if (
          !prevState.isWaitingForNext &&
          reason !== p.reason &&
          p.reason === "playbackEnded"
        ) {
          console.warn("endPlayback next...");
          isWaitingForNext = true;
          SubsonicActions.playerNext.defer();
        }
      }

      // hack - no easy way to pass this around
      if (window.bookmarkSeekTo) {
        window.castSubsonic.seekMedia(window.bookmarkSeekTo);
        window.bookmarkSeekTo = null;
      }

      if (prevState.showBackground !== p.isPlaying) {
        showBackground = p.isPlaying;
      }

      // if (p.reason !== 'normal') console.log('p', p);
      const { currentMediaTimeString, durationString } = p;

      newState = {
        currentPlaying,
        currentFile,
        coverArt,
        mediaSrc,
        mediaIcon,
        coverSize,
        showBackground,
        isWaitingForNext,
        currentMediaTimeString,
        durationString,
        reason: csProps.reason
      };
    }
    return Object.assign({}, prevState, newState);
  }

  constructor(props) {
    super(props);
    this.state = {
      teethTotal: props.toothbrushLength,
      teeth: props.toothbrushLength,
      original: props.match.params.l * 1,
      remaining: props.match.params.l * 1,
      isPlaying: false
    };
  }

  // audioUpdates = p => {
  //   // hack - no easy way to pass this around
  //   if (window.bookmarkSeekTo) {
  //     window.castSubsonic.seekMedia(window.bookmarkSeekTo);
  //     window.bookmarkSeekTo = null;
  //   }
  //
  //   if (this.state.showBackground !== p.isPlaying) {
  //     this.setState({showBackground: p.isPlaying});
  //   }
  //
  //   // if (p.reason !== 'normal') console.log('p', p);
  //
  //   if (!this.state.isWaitingForNext && p.reason === "playbackEnded") {
  //     console.log("endPlayback next...");
  //     this.setState({isWaitingForNext: true});
  //     SubsonicActions.playerNext.defer();
  //   }
  //
  //   this.setState({isPlaying: p.isPlaying});
  // };

  increment = () => {
    var { isPlaying } = this.props.csProps;
    var { teeth, remaining } = this.state;
    // console.log('increment', this.state);
    if (!isPlaying) return; // paused
    teeth = Math.max(teeth - 1, 0);
    remaining = Math.max(remaining - 1, 0);
    this.setState({ teeth, remaining });
    // console.log('increment', teeth, remaining);
    if (remaining === 0) this.props.history.goBack();
  };

  componentDidMount() {
    console.log("starting timer");
    this.timer = setInterval(this.increment, 1000);
  }

  // not every player will do this - if we keep an inline playing frame a-la Plex, then this needs to not happen here.
  // stop: control the player because of internal state; pause = toggle autoplay
  componentWillUnmount() {
    window.cp.playerHandler.stop();
    ColorActions.resetColors();
    clearInterval(this.timer);

    const background =
      "linear-gradient(0deg, rgba(0,0,0,1) 54%, rgba(106,27,154,1) 65%, rgba(156,39,176,1) 80%)";
    document.getElementsByTagName("body")[0].style.background = background;
    document.getElementsByTagName("audio")[0].src = "";
  }

  toggleBookmark = currentMediaTime => {
    // console.log('toggleBookmark', currentMediaTime, this.props);
    const { subsonic } = this.props;
    const { currentPlaying } = subsonic;
    const id = currentPlaying.queue[currentPlaying.idx].id;

    if (subsonic.bookmarksById[id]) {
      SubsonicActions.deleteBookmark(id);
    } else {
      SubsonicActions.createBookmark(id, Math.trunc(currentMediaTime * 1000));
    }
  };

  render() {
    // console.log('TimerPlayer', this.props, this.state);

    const {
      subsonic,
      SubsonicSource,
      useGradients,
      useFuzzyImage,
      csProps
    } = this.props;
    const { currentPlaying } = subsonic;
    // console.log('following', following);
    // const id =
    //   currentPlaying &&
    //   currentPlaying.queue &&
    //   currentPlaying.queue[currentPlaying.idx]
    //     ? currentPlaying.queue[currentPlaying.idx].id
    //     : "-1";

    if (!currentPlaying.queue.length) {
      let message = `It looks like there may be a problem loading the player. If this does not go away, then please go back and try again.`;
      return (
        <Card>
          <CardContent>
            <Typography component="div">{message}</Typography>
          </CardContent>
          <CardActions>
            <Button onClick={this.props.history.goBack}>Go Back</Button>
            <Button component={Link} to="/">
              Go Home
            </Button>
          </CardActions>
        </Card>
      );
    }

    const { showBackground, currentFile = { id: "-1" }, coverArt } = this.state;
    const c = currentFile;
    const background = useGradients ? (
      <ColorContainer>
        <GradientBackground showGradients={showBackground} />
      </ColorContainer>
    ) : useFuzzyImage ? (
      <FuzzyImageBackground
        selector=".page-bg"
        image={coverArt}
        showBackground={showBackground}
      />
    ) : (
      <span />
    );

    const portrait = window.innerWidth < window.innerHeight - 64;
    const responsiveCoverArt = SubsonicSource.getCoverArtURL(
      currentFile.id,
      600
    );

    const dialSize = portrait
      ? window.innerWidth / 2 - 16
      : Math.min(window.innerHeight - 64 - 16, window.innerWidth / 2 - 16);
    const imageSize = portrait
      ? Math.trunc(2 * (window.innerWidth / 3)) - 16
      : Math.min(window.innerHeight - 64 - 16, window.innerWidth / 2 - 16);

    const notification = (
      <Notification
        mediaIcon={this.state.mediaIcon}
        song={this.state.currentFile}
        {...csProps}
      />
    );

    return (
      <Grid container={true} spacing={1}>
        <Grid
          item={true}
          xs={3}
          hidden={{
            xsUp: !portrait
          }}
          zeroMinWidth={true}
        />
        <Grid item={true} xs={6}>
          <ColorContainer>
            <PaletteDial {...csProps} {...this.state} size={dialSize} />
          </ColorContainer>
        </Grid>
        <Hidden xsUp={!portrait}>
          <Grid item={true} xs={3} zeroMinWidth={true} />
        </Hidden>

        <Grid item={true} xs={portrait ? 8 : 6}>
          <Hidden xsUp={portrait}>
            <HollowCard>
              {c.title}
              <br />({currentPlaying.idx + 1}/ {currentPlaying.queue.length})
            </HollowCard>
          </Hidden>
          <GradientGeneratingImage
            src={responsiveCoverArt}
            size={imageSize + "px"}
          />
        </Grid>

        <Hidden xsUp={!portrait}>
          <Grid item={true} xs={4}>
            <HollowCard>
              {c.title}
              <br />({currentPlaying.idx + 1}/ {currentPlaying.queue.length})
            </HollowCard>
          </Grid>
        </Hidden>

        <PlayPauseButton {...csProps} fab="fab" standalone="standalone" />
        {notification}
        {background}
      </Grid>
    );
  }
}

export default TimerPlayer;

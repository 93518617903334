import React, { Component } from "react";

import TimerDial from "./TimerDial";
import { RGB } from "subfirebase/lib/utils/colors";
import { hexColorToMaterial } from "subfirebase/lib/utils/material-color";
import NearestColor from "../nearestColor";

class PaletteDial extends Component {
  render() {
    const palette = this.props.palette;
    let colors = {};
    if (palette) {
      //console.log(palette);
      const paletteHexMaterial = palette.map(
        v => hexColorToMaterial(new RGB(v).toHexString()).closestMaterialHex
      );
      // console.log(paletteHexMaterial);
      const paletteHexMaterialObject = {};
      paletteHexMaterial.forEach(v => {
        paletteHexMaterialObject[v] = v;
      });

      var nearestColor = NearestColor.from(paletteHexMaterialObject);
      colors = {
        loopColor: "white",
        teethColor: nearestColor("#1A237E"),
        plentyRemainingTimeColor: nearestColor("#C8E6C9"),
        normalRemainingTimeColor: nearestColor("#81C784"),
        notMuchRemainingTimeColor: nearestColor("#BF360C")
      };
    }
    return <TimerDial {...this.props} {...colors} />;
  }
}

export default PaletteDial;
